import React, { useCallback, useEffect, useState } from 'react'
import { Popover } from '../Popover/Popover'
import { SubscribersList } from './SubscribersList/SubscribersList'
import { SwapUsersSelect } from './SwapUsersSelect/SwapUsersSelect'
import { User } from './User/User'
import styles from './View4.module.scss'

export const View4 = ({ subscribers = [], publisher, handleFoolScreenVideoStream, toggleSubscribeAudio }) => {
  const [visibleUsers, setVisibleUsers] = useState([0, 1, 2, 3, 4, 5, 6, 7])
  const [visiblePopover, setVisiblePopover] = useState(false)

  const visiblePopoverFasle = useCallback(e => {
    if (e.key === 'Escape') {
      setVisiblePopover(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', visiblePopoverFasle)
    return () => {
      document.removeEventListener('keydown', visiblePopoverFasle)
    }
  }, [])

  const handleSwapUsers = user => {
    const newVisibleUsers = [...visibleUsers]
    newVisibleUsers[visiblePopover] = usersForSelect.indexOf(user)
    setVisibleUsers(newVisibleUsers)
    setVisiblePopover(false)
  }

  const usersForSelect = [publisher, ...subscribers]

  const handleToMainStream = index => {
    const newVisibleUsers = [...visibleUsers]
    newVisibleUsers[0] = visibleUsers[index]
    newVisibleUsers[index] = visibleUsers[0]
    setVisibleUsers(newVisibleUsers)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.mains}>
        {
          visibleUsers.map((e, index) =>
            <Popover
              onHide={() => setVisiblePopover(false)}
              visible={visiblePopover === index}
              content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
            >
              <div className={styles.user}>
                {usersForSelect[e] ? (
                    <User
                      openUsersList={() => usersForSelect.length && setVisiblePopover(index)}
                      streamManager={usersForSelect[e]}
                      handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                      toggleSubscribeAudio={toggleSubscribeAudio}

                    />
                ) : null}
              </div>
            </Popover>
          )
        }
      </div>
      <SubscribersList users={usersForSelect} />
    </div>
  )
}
