export const version = "01.12.4"

export const BACKEND_URL_PREFIX = process.env.REACT_APP_BACKEND_URL
export const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN



export const MONTHS = [
  "",
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];
